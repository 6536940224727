import {useEffect, useRef, useState} from 'react';
import {EPerformanceMark} from 'app/services/performance/EPerformance';
import {measureAdSlotPerformance} from 'app/services/performance/PerformanceMeasurments';
import {markAdServerEvent} from 'app/services/performance/PerformanceMarks';

const disabledSlots = ['ad-Sponsor_1', 'web_interstitial'];

const adServerEventsToPerformanceMarkMapping = {
  slotRequested: EPerformanceMark.SLOTREQUESTED,
  slotResponseReceived: EPerformanceMark.SLOTRESPONSERECEIVED,
  slotOnload: EPerformanceMark.SLOTONLOAD,
  slotRenderEnded: EPerformanceMark.SLOTRENDERENDED,
};

const prebidEventsToPerformanceMarkMapping = {
  auctionInit: EPerformanceMark.AUCTIONSTARTED,
  auctionEnd: EPerformanceMark.AUCTIONENDED,
};

const adEventsToPerformanceMarkMapping = {
  ...adServerEventsToPerformanceMarkMapping,
  ...prebidEventsToPerformanceMarkMapping,
};

const adServerEvents = Object.keys(adServerEventsToPerformanceMarkMapping);
const prebidEvents = Object.keys(prebidEventsToPerformanceMarkMapping);

const totalAdEvents = adServerEvents.concat(prebidEvents);

const useInitAdSlotPerformance = () => {
  const initRef = Object.fromEntries(totalAdEvents.map(eventName => [eventName, false]));
  const executedHandlersRef = useRef(initRef);

  const [allEventsTriggered, setAllEventsTriggered] = useState(false);
  const [handlersInitialized, setHandlersInitialized] = useState(false);
  const eventHandlersRef = useRef<Record<string, (event: any) => void>>({});

  useEffect(() => {
    eventHandlersRef.current = totalAdEvents.reduce((handlers, eventName) => {
      handlers[eventName] = (event: any) => {
        const slotId = event.slot ? event.slot.getSlotElementId() : 'not determinable for prebid event';
        const disablePerformanceMarker = disabledSlots.find(disabledSlotsId => slotId.includes(disabledSlotsId));
        if (disablePerformanceMarker || executedHandlersRef.current[eventName]) {
          return;
        }
        markAdServerEvent(adEventsToPerformanceMarkMapping[eventName], slotId);

        executedHandlersRef.current = {...executedHandlersRef.current, [eventName]: true};
        if (Object.values(executedHandlersRef.current).every(value => value)) {
          setAllEventsTriggered(true);
        }
      };
      return handlers;
    }, {} as Record<string, (event: any) => void>);

    setHandlersInitialized(true);
  }, []);

  const addEventListeners = () => {
    window.googletag.cmd.push(() => {
      adServerEvents.forEach(eventName => {
        const handler = eventHandlersRef.current[eventName];
        if (handler) {
          window.googletag.pubads().addEventListener(eventName, handler);
        }
      });
    });

    window.pbjs.que.push(() => {
      prebidEvents.forEach(eventName => {
        const handler = eventHandlersRef.current[eventName];
        if (handler) {
          window.pbjs.onEvent(eventName, handler);
        }
      });
    });
  };

  const removeEventListeners = () => {
    window.googletag.cmd.push(() => {
      adServerEvents.forEach(eventName => {
        const handler = eventHandlersRef.current[eventName];
        if (handler) {
          window.googletag.pubads().removeEventListener(eventName, handler);
        }
      });
    });

    window.pbjs.que.push(() => {
      prebidEvents.forEach(eventName => {
        const handler = eventHandlersRef.current[eventName];
        if (handler) {
          window.pbjs.offEvent(eventName, handler);
        }
      });
    });
  };

  useEffect(() => {
    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, [handlersInitialized]);

  useEffect(() => {
    if (allEventsTriggered) {
      removeEventListeners();
      measureAdSlotPerformance();
    }
  }, [allEventsTriggered]);
};

export default useInitAdSlotPerformance;
