const PREFIX = 'FuPa:';

export enum EPerformanceMark {
  HYDRATIONCOMPLETED = `${PREFIX}HydrationCompleted`,
  TCDATAEVENTSTATUS = `${PREFIX}TcDataEventStatus`,
  TCFAPIREADY = `${PREFIX}TcfApiReady`,
  ADSERVERREADY = `${PREFIX}QMAdserverReady`,
  AUCTIONSTARTED = `${PREFIX}PrebidAuctionStarted`,
  AUCTIONENDED = `${PREFIX}PrebidAuctionEnded`,
  SLOTREQUESTED = `${PREFIX}SlotRequested`,
  SLOTRESPONSERECEIVED = `${PREFIX}SlotResponseReceived`,
  SLOTONLOAD = `${PREFIX}SlotOnload`,
  SLOTRENDERENDED = `${PREFIX}SlotRenderEnded`,
}

export enum EPerformanceMeasure {
  HYDRATIONDURATION = `${PREFIX}HydrationDuration`,
  SLOTTOTALDURATION = `${PREFIX}SlotTotalDuration`,
  AUCTIONDURATION = `${PREFIX}PrebidAuctionDuration`,
  SLOTRESPONSEDURATION = `${PREFIX}SlotResponseReceivedDuration`,
  SLOTSTARTLOADDURATION = `${PREFIX}SlotOnLoadDuration`,
  SLOTRENDERINGDURATION = `${PREFIX}SlotRenderEndedDuration`,
}
